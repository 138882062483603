import { createApp } from 'vue/dist/vue.esm-bundler'
import App from './App.vue'
import './styles/app.css';
import router from './router.js'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import "@vueform/toggle/themes/default.css"
import "@vueform/multiselect/themes/default.css"



const options = {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674',
  backdrop: false,
};

const app = createApp(App)

app.use(router)
.use( VueSweetalert2, options)
.mount('#app')

app.config.globalProperties.window = window