<template>
  <div>
    <div>
      <QuillEditor ref="myEditor" contentType="html" v-model:content="editedContent" :modules="modules" :toolbar="[['bold', 'italic', 'underline'],
        ['link', 'image'],
        formats=[
					['bold', 'italic', 'underline'], 'strike', 'blockquote',
					['link', 'image'],
					{ 'align': [] }
				]]"/>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { QuillEditor } from '@vueup/vue-quill'
import ImageCompress from 'quill-image-compress'

export default defineComponent({
  components: {
    QuillEditor,
  },
  props: {
    showEditor: {
      Boolean
    },
    myContent: {
      String
    }
  },
  emits: ['update:myContent'],
  computed: {
    editedContent: {
      get() {
        return this.myContent
      },
      set(value) {
        this.$emit('update:myContent', value)
      }
    }
  },
  watch: {
    showEditor(val) {
      if (val) {
        this.$refs.myEditor.setContents([])
      }
    }
  },
  setup: () => {
    const modules = {
      name: 'imageCompress',  
      module: ImageCompress, 
      options: {
        quality: 0.9,
        maxWidth: 1200,
        maxHeight: 1200, 
      }
    }
    return { modules }
  }
})


</script>