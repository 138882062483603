import userApi from "@/services/apiConnection.js"
import router from "@/router.js"

const apiCalls = {
  loginUser(alertFun, username, password) {
    return userApi(alertFun)
      .post("/login", {
        username: username,
        password: password,
      })
      .then(
        (response) => {
          console.log("LOGIN RESPONSE", response)
          //response.data.username = username
          localStorage.setItem("user-token", response.data.token)
          router.push("/")
          return response.data
        },
        (error) => {
          console.log("De bidde fel")
          console.log("Felet:", error)
          let errorStatus = error.response.data.statusText
          let errorMessage = ""
          localStorage.removeItem("user-token")

          if (errorStatus === "No match for user in database") {
            errorMessage =
              "Ingen användare finns registrerad med det användarnamnet"
          } else if (errorStatus === "User not verified") {
            errorMessage =
              "Användaren är ännu inte aktiverad. Du ska ha fått en länk för aktivering till din mail."
          } else if (errorStatus === "Current password does not match") {
            errorMessage = "Du har angivit ett felaktigt lösenord"
          }
          router.push({
            name: "Error",
            params: { message: errorMessage },
          })
          throw error
        }
      )
  },
  createUser(alertFun, firstname, lastname, username, email, password) {
    return userApi(alertFun)
      .post("/createuser", {
        firstname: firstname,
        lastname: lastname,
        username: username,
        email: email,
        password: password,
      })
      .then(
        (response) => {
          console.log("Response from createUser", response)
          return response.data
        },
        (error) => {
          console.log("De bidde fel i registreringen")
          console.log(error)
          throw error
        }
      )
  },
  setPassword(alertFun, userid, code, password,) {
    return userApi(alertFun)
      .post("/setpass", {
        userid,
        code,
        password
      })
      .then(
        (resp) => {
          console.log("Svar från setPassword", resp)
          return resp
        },
        (err) => {
          console.log("Kunde ej uppdatera lösenord", err)
          throw err
        }
      )
  },
  changePassword(alertFun, password, newPassword) {
    console.log("From apiCalls: pswd: " + password + " Newpass: ", newPassword)
    return userApi(alertFun)
      .post("/changepassword", {
        password,
        newPassword,
      })
      .then(
        (resp) => {
          console.log("Svar från updatePassword", resp)
          return resp
        },
        (err) => {
          console.log("Kunde ej byta lösenord", err)
          throw err
        }
      )
  },
  getArticles(alertFun) {
    return userApi(alertFun).get("/getarticles").then(
      (resp) => {
        return resp
      },
      (err) => console.log(err)
    )
  },
  addArticle(alertFun, article) {
    console.log("Article from addArticle", article)
    return userApi(alertFun)
      .post("/addarticle", {
        heading: article.heading,
        story: article.story,
        category: article.category,
        year: article.year,
        tags: article.tags
      })
      .then(
        (resp) => {
          console.log("Svar från addArticle", resp)
          return resp
        },
        (err) => {
          console.log("Kunde ej lägga till artikel", err)
          throw err
        }
      )
  },
  editArticle(alertFun, article) {
    console.log("Article", article)
    return userApi(alertFun)
      .post("/editarticle", {
        heading: article.heading,
        story: article.story,
        category: article.category,
        year: article.year,
        id: article.id,
        tags: article.tags
      })
      .then(
        (resp) => {
          console.log("Svar från editArticle", resp)
          return resp
        },
        (err) => {
          console.log("Kunde ej redigera till artikel", err)
          throw err
        }
      )
  },
  deleteArticle(alertFun, id) {
    console.log("delete id", id)
    return userApi(alertFun)
      .post("/deletearticle", {
        id: id
      }
      ).then(
        (resp) => {
          console.log("Svar från deleteArticle", resp)
          return resp
        },
        (err) => {
          console.log("Kunde ej ta bort artikel", err)
          throw err
        }
      )
  },
  removePermanently(alertFun, ids) {
    console.log("delete id", ids)
    return userApi(alertFun)
      .post("/removepermanently", {
        ids: ids
      }
      ).then(
        (resp) => {
          console.log("Svar från removePermanently", resp)
          return resp
        },
        (err) => {
          console.log("Kunde ej ta bort artikel", err)
          throw err
        }
      )
  },
  getStoryTags(alertFun) {
    return userApi(alertFun).get("/getstorytags").then(
      (resp) => {
        return resp
      },
      (err) => console.log(err)
    )
  },
  removeEmptyTags(alertFun) {
    return userApi(alertFun).get("/removeemptytags").then(
      (resp) => {
        return resp
      },
      (err) => console.log(err)
    )
  },
  getTagLinks(alertFun) {
    return userApi(alertFun).get("/gettaglinks").then(
      (resp) => {
        return resp
      },
      (err) => console.log(err)
    )
  },
  getCategories(alertFun) {
    return userApi(alertFun).get("/getcategories").then(
      (resp) => {
        return resp
      },
      (err) => console.log(err)
    )
  },
  getUserLevel(alertFun) {
    return userApi(alertFun).get("/getuserlevel").then(
      (resp) => {
        console.log("UserLevel", resp)
        return resp
      },
      (err) => console.log(err)
    )
  }
}

export default apiCalls